<template>
  <!-- 最外层的背景阴影  必须-->
  <div class="modal" v-if="showIfNeed">
    <!-- 模态框  必须-->
    <div class="modal-content" :style="{height:height+'px', width:width+'px'}">
      <!-- 模态框的右上角删除按钮 可选-->
      <Button type="text" class="cancel" @click="cancel">
        <Icon type="md-close" :size="20"></Icon>
      </Button>

      <!-- 模态框的标题 可选-->
      <h2>投诉建议</h2>

      <!-- modal的内容，后期所有的页面都写在此 div内部  必须-->
      <div class="content" :style="{height:contentHeight+'px'}">
        <Form :label-width="110" :model="formMoreData" ref="formMoreData" :rules="formRules">
          <Form-Item label="主题" prop="theme">
            <Input
              placeholder="请概要填写您标题信息"
              v-model="formMoreData.theme"
              @on-keyup="btKeyUp('theme')"
              @on-keydown="btKeyDown('theme')"
            />
          </Form-Item>
          <Form-Item label="内容">
            <Input
              type="textarea"
              :autosize="{minRows: 3,maxRows: 5}"
              placeholder="请填写建议内容"
              v-model="formMoreData.content"
            />
          </Form-Item>
          <Form-item label="上传附件" prop="publicityImag">
            <div
              class="demo-upload-list"
              v-for="(item,index) in formMoreData.attachmentList"
              :key="index"
            >
              <!-- <img :src="item.iconUrl" />
              <div class="demo-upload-list-cover">
                <Icon type="ios-eye-outline" @click.native="handleView(item.fileUrl)"></Icon>
                <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
              </div>-->
              <div
                v-if="item.fileUrl.substr(item.fileUrl.lastIndexOf('.') + 1)=='jpg'||item.fileUrl.substr(item.fileUrl.lastIndexOf('.') + 1)=='jpeg' ||item.fileUrl.substr(item.fileUrl.lastIndexOf('.') + 1)=='png' "
              >
                <img :src="item.iconUrl" />
                <div class="demo-upload-list-cover">
                  <Icon type="ios-eye-outline" @click.native="handleView(item.fileUrl)"></Icon>
                  <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
                </div>
              </div>

              <div v-if="item.fileUrl.substr(item.fileUrl.lastIndexOf('.') + 1)=='pdf' ">
                <img src="@/assets/images/pdfImg.png" />
                <div class="demo-upload-list-cover">
                  <Icon type="ios-eye-outline" @click.native="handlePdf(item)"></Icon>
                  <Icon type="ios-trash-outline" @click.native="handleRemovePdf(item)"></Icon>
                </div>
              </div>

              <div
                v-if="item.fileUrl.substr(item.fileUrl.lastIndexOf('.') + 1)=='doc'||item.fileUrl.substr(item.fileUrl.lastIndexOf('.') + 1)=='docx' ||item.fileUrl.substr(item.fileUrl.lastIndexOf('.') + 1)=='dotx' ||item.fileUrl.substr(item.fileUrl.lastIndexOf('.') + 1)=='dot' "
              >
                <img src="@/assets/images/wordImg.png" />
                <div class="demo-upload-list-cover">
                  <Icon type="ios-eye-outline" @click.native="handleWord(item)"></Icon>
                  <Icon type="ios-trash-outline" @click.native="handleRemoveWord(item)"></Icon>
                </div>
              </div>

              <div
                v-if="item.fileUrl.substr(item.fileUrl.lastIndexOf('.') + 1)=='xls'||item.fileUrl.substr(item.fileUrl.lastIndexOf('.') + 1)=='xlsx'"
              >
                <img src="@/assets/images/excelImg.png" />
                <div class="demo-upload-list-cover">
                  <Icon type="ios-arrow-round-down" @click.native="downExcel(item)"></Icon>
                  <Icon type="ios-trash-outline" @click.native="handleRemoveExcel(item)"></Icon>
                </div>
              </div>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </div>
            <Upload
              ref="upload"
              :show-upload-list="false"
              :default-file-list="defaultList"
              :on-success="handleSuccess"
              :format="format"
              :max-size="10240"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              :before-upload="handleBeforeUpload"
              multiple
              type="drag"
              :action="uploadUrl"
              :headers="fileData"
              style="display: inline-block;width:58px;"
            >
              <div style="width: 58px;height:58px;line-height: 58px;">
                <Icon type="ios-camera" size="20"></Icon>
              </div>
            </Upload>
            <Modal title="附件查看" v-model="visible" class="imgShow">
              <img :src="'' + showName + ''" v-if="visible" style="width: 100%" />
            </Modal>
          </Form-item>
          <Form-Item label="联系电话" prop="mobile">
            <Input placeholder="请填写您的手机号" v-model="formMoreData.mobile" :maxlength="11" />
          </Form-Item>
          <Form-Item label="姓名/企业名称">
            <Input placeholder="请填写您的姓名/企业名称" v-model="formMoreData.name" />
          </Form-Item>
          <Form-Item>
            <!-- 模态框底部按钮，样式已设置，默认从右到左（第一个按钮在右边） 可选-->
            <div class="modal-content-footer">
              <Button @click="cancel" class="nextCancel">取消</Button>
              <Button class="sure" @click="sure('formMoreData')">确定</Button>
            </div>
          </Form-Item>
        </Form>
      </div>
    </div>

    <Modal
      v-model="isShowPdf"
      title="附件查看"
      @on-ok="comfirmOk"
      @on-cancel="comfirmCancel"
      ref="modal"
      class="pafModal"
      width="800"
    >
      <div id="pdf-content"></div>
    </Modal>
  </div>
</template>

<script>
import { goComplaint } from "@/api/userCenter.api";
import PDFObject from "pdfobject";
export default {
  name: "complaint-proposal",
  props: {
    /*弹窗的宽度 默认800px*/
    width: {
      type: Number,
      default: 800
    },

    /*弹窗的高度 默认500px*/
    height: {
      type: Number,
      default: 500
    },

    /* 控制显示隐藏 */
    showObj: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback("手机号格式不正确");
      } else {
        callback();
      }
    };
    return {
      /* contentHeight 这个必须存在，用于动态计算content的高度 70为头部标题的高度， 80为底部按钮的高度，若无按钮可将此高度删除*/
      uploadUrl: this.$config.base.baseUrl + "/file/file_upload/upload",
      contentHeight: this.height - 70 - 80,
      isShowPdf: false,
      pdfPath: "",
      formMoreData: {
        theme: "", //主题
        content: "", //内容
        mobile: "", //联系电话
        name: "", //姓名/企业用户
        attachmentList: [] //上传的附件
      },
      formRules: {
        theme: [
          {
            required: true,
            message: "请输入主题,特殊字符除外",
            trigger: "change"
          }
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "change"
          },
          {
            required: true,
            validator: validatePhone,
            trigger: "change"
          }
        ]
      },
      format: [
        "jpeg",
        "png",
        "jpg",
        "gif",
        "doc",
        "docx",
        "dotx",
        "dot",
        "xls",
        "xlsx",
        "pdf"
      ], //上传的文件类型
      fileData: {
        customerToken: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).customer_token
          : ""
      },
      showName: "",
      visible: false,
      uploadList: [],
      uploadArr: [],
      defaultList: []
    };
  },
  computed: {
    // 必须存在showIfNeed
    showIfNeed: {
      get() {
        return this.showObj;
      },
      set(val) {
        this.$emit("update:showObj", val);
      }
    }
  },
  methods: {
    cancel() {
      this.showIfNeed = false;
      this.formMoreData = {};
    },
    handleView(fileUrl) {
      this.showName = fileUrl;
      this.visible = true;
    },
    handleRemove(file) {
      const uploadList = this.uploadList;
      //   this.uploadArr.splice(this.uploadArr.indexOf(file), 1);
      this.formMoreData.attachmentList.splice(
        this.formMoreData.attachmentList.indexOf(file),
        1
      );
    },
    handleSuccess(response, file, fileList) {
      if (!response.success || response.data.length == 0) {
        this.$Message.error("文件" + file.name + "上传失败,请重新上传！");
        return;
      } else {
        this.formMoreData.attachmentList.push(response.data[0]);
      }
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "文件格式不正确",
        desc: "文件格式 " + file.name + " 不正确."
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "超过文件大小限制",
        desc: "文件  " + file.name + " 太大了, 不超过 10M."
      });
    },
    handleBeforeUpload() {
      const check = this.uploadList.length < 5;
      if (!check) {
        this.$Notice.warning({
          title: "最多上传五个文件."
        });
      }
      return check;
    },
    //只能输入汉字英文数字
    btKeyDown(name) {
      this.formMoreData[name] = this.formMoreData[name].replace(
        /[^\w^\s^\u4e00-\u9fa5]/gi,
        ""
      );
    },
    //控制不能输入特殊字符
    btKeyUp(name) {
      this.formMoreData[name] = this.formMoreData[name].replace(
        /[^\w^\s^\u4e00-\u9fa5]/gi,
        ""
      );
    },
    //提交投诉
    sure(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          goComplaint({
            subject: this.formMoreData.theme,
            content: this.formMoreData.content,
            mobile: this.formMoreData.mobile,
            name: this.formMoreData.name,
            attachmentList: this.formMoreData.attachmentList
          })
            .then(res => {
              if (res.success) {
                this.$Message.success("投诉成功,管理人员会及时改正!");
                // this.formMoreData = {};
                this.$refs[name].resetFields();
                this.formMoreData.content = "";
                this.formMoreData.name = "";
                this.formMoreData.attachmentList = [];
                this.showIfNeed = false;
              } else {
                this.$Message.error(res.msg);
              }
            })
            .catch(err => {
              this.$Message.error("投诉失败,请稍后在试！");
            });
        }
      });
    },
    comfirmOk() {
      this.isShowPdf = false;
    },
    comfirmCancel() {
      this.isShowPdf = false;
    },
    //查看pdf
    handlePdf(data) {
      console.log(data);
      this.pdfPath = data.fileUrl;
      this.isShowPdf = true;
    },
    //删除pdf
    handleRemovePdf(data) {
      let pdfIndex = this.formMoreData.attachmentList.findIndex(
        el => el == data
      );
      this.formMoreData.attachmentList.splice(pdfIndex, 1);
    },
    //查看word
    handleWord(data) {
      console.log(data);
      this.pdfPath = data.pdfUrl;
      this.isShowPdf = true;
    },
    //删除word
    handleRemoveWord(data) {
      let wordIndex = this.formMoreData.attachmentList.findIndex(
        el => el == data
      );
      this.formMoreData.attachmentList.splice(wordIndex, 1);
    },
    //下载excel
    downExcel(data) {
      window.location.href = data.fileUrl;
    },
    //删除excel
    handleRemoveExcel(data) {
      let excelIndex = this.formMoreData.attachmentList.findIndex(
        el => el == data
      );
      this.formMoreData.attachmentList.splice(excelIndex, 1);
    }
  },
  created() {},
  mounted() {
    PDFObject.embed(this.pdfPath, "#pdf-content");
  },
  watch: {
    pdfPath(newName, oldName) {
      this.$nextTick(function() {
        console.log(newName);
        PDFObject.embed(newName, "#pdf-content");
        this.pdfPath = newName;
      });
    }
  }
};
</script>

<style scoped>
.sure {
  background-color: #ea5413 !important;
  color: white !important;
}
.nextCancel {
  background-color: #c7c7c7 !important;
  color: white !important;
}
.demo-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
.content >>> .ivu-form-item-content {
  text-align: left;
}
#pdf-content {
  width: 100%;
  height: 600px;
}
.pafModal >>> .ivu-btn-primary {
  background-color: #ea5414;
  border-color: #ea5414;
}
.imgShow {
  height: 400px;
}
.imgShow >>> .ivu-btn-primary {
  background-color: #ea5414;
  border-color: #ea5414;
}
.content >>> .ivu-input {
  font-size: 14px;
}
.content >>> .ivu-form .ivu-form-item-label {
  font-size: 14px;
}
</style>