<template>
  <div class="contactUs-container">
    <header-nav></header-nav>
    <div class="main">
      <div class="main-header">
        <!-- <div class="header-content">
          <img src="@/assets/images/contact/contactus_banner_icon.png" alt width="99px" />
          <p class="title" style="color: #ffffff;">联系我们</p>
        </div> -->
      </div>
      <div class="contact_list">
        <p class="title">联系我们</p>
        <span class="titles">CONTACT&nbsp;&nbsp;US</span>
        <p class="line"></p>

        <div class="box">
          <img
            class="leftImgs"
            src="../assets/images/contact/bgc.png"
            alt=""
          />
          
          <div class="content" v-html="contactUsInfo.content"></div>
        </div>

        <!-- <div class="content" v-html="contactUsInfo.content"></div> -->
         
        <!-- <Row type="flex" justify="space-between" class="code-row-bg" style="margin-top:20px">
          <i-col span="7" class="hotLine">
            <img src="@/assets/images/contact/phone_icon01.png" alt class="hontLineIMg" />
            <dl class="hotLine-content">
              <dt class="contact_title">招租热线</dt>
              <dt class="contact_content">电话:12349876112</dt>
            </dl>
          </i-col>
          <i-col span="7" class="hotLine">
            <img src="@/assets/images/contact/phone_icon01.png" alt class="hontLineIMg" />
            <dl class="hotLine-content">
              <dt class="contact_title">投诉热线</dt>
              <dt class="contact_content">电话:12349876112</dt>
            </dl>
          </i-col>
          <i-col span="7" class="hotLine">
            <img src="@/assets/images/contact/phone_icon01.png" alt class="hontLineIMg" />
            <dl class="hotLine-content">
              <dt class="contact_title">企业客户联系热线</dt>
              <dt class="contact_content">电话:12349876112</dt>
            </dl>
          </i-col>
        </Row> -->
      </div>
      <!-- <div style="width:100%;background: #FAFAFA;padding-bottom:40px;padding-top:40px"> -->
        <!-- <div class="contact_list"> -->
          <!-- <p class="title">加入我们</p> -->
          <!-- <span style="font-size:18px">JION&nbsp;&nbsp;US</span> -->
          <!-- <p class="line"></p> -->
          <!-- <Row type="flex" justify="space-between" class="code-row-bg" style="margin-top:20px">
            <i-col span="7" class="joinContent">
              <p class="duty">运营部-运营主管/经理</p>
              <p class="experience">两年以上工作经验</p>
            </i-col>
            <i-col span="7" class="joinContent">
              <p class="duty">运营部-销售经理 / 主管</p>
              <p class="experience">两年以上工作经验</p>
            </i-col>
            <i-col span="7" class="joinContent">
              <p class="duty">人事经理 / 主管</p>
              <p class="experience">十年以上工作经验</p>
            </i-col>
            <i-col span="7" class="joinContent">
              <p class="duty">客服经理 /主管</p>
              <p class="experience">三年以上工作经验</p>
            </i-col>
            <i-col span="7" class="joinContent">
              <p class="duty">产品设计经理 / 主管</p>
              <p class="experience">两年以上工作经验</p>
            </i-col>
            <i-col span="7" class="joinContent">
              <p class="duty">线上运营经理 / 主管</p>
              <p class="experience">两年以上工作经验</p>
            </i-col>
          </Row> -->
        <!-- </div> -->
        <!-- <div class="email"> -->
          <!-- <P>简历请发至HR邮箱:zhangxx@.163.com</P>
          <p style="padding-top:10px">命名方式:岗位-工作年限-姓名</p> -->
          <!-- <Button type="default" style="margin-top:10px;width:200px;height:50px;font-size:16px">查看更多岗位</Button> -->
        <!-- </div> -->
      <!-- </div> -->

      <!-- <div class="contact_list"> -->
        <!-- <p class="title">商务合作</p> -->
        <!-- <span style="font-size:18px">COOPERATION</span> -->
        <!-- <p class="line"></p> -->
      <!-- </div> -->

      <!-- <div class="bgc_cooperation"></div> -->

      <!-- <div class="cooperation_content">
        <div>
          <p class="title-content">我们一起在做什么</p>
          <p
            class="content-detail"
            style=" letter-spacing: 2px;"
          >依托自身综合开发运营能力和“全产品线”的集成服务体系，聚焦商业地产、产业地产、租赁住房三大业务，全方位提供“模块化、集成化、一站式”的新型产城综合开发运营服务和“全周期”、“全产品线”的市场化解决方案，致力成为国内领先的城市功能优化服务商。</p>
        </div>
        <div>
          <p class="title-content">我们可以带来什么</p>
          <p class="content-detail" style=" letter-spacing: 2px;">
            “恒泰星寓”品牌公寓由苏州恒泰控股集团有限公司旗下苏州工业园区恒泰租赁住房有限公司开发和打造。
            产品包括有集中式租赁住房小区和分散式长租公寓等项目。围绕多样化的租住需求，提供满足不同需求的多种租赁空间产品，配以安全、温馨、周到的服务，为城市发展所需的各层次人才营造整洁、舒适、设施齐全的居住空间，并不断丰富和提供优质的生活配套管理服务。
          </p>
        </div>
        <div>
          <p class="title-content">合作邀请</p>
          <p class="content-detail">
            我们诚挚邀请
            <span class="cooperation_detail">媒体合作</span>
            <span class="cooperation_detail">市场商务合作</span>
            <span class="cooperation_detail">房源合作</span> 房源合作类合租商，一起打造星寓
          </p>
          <p class="content-detail">
            请提交您的
            <span style="margin-left:20px">
              <img src="@/assets/images/contact/data_icon01.png" alt style="width:14px;height:14px" />
              <span style="margin-left:5px;font-size:14px;font-weight:500">姓名</span>
            </span>
            <span style="margin-left:20px">
              <img src="@/assets/images/contact/data_icon02.png" alt style="width:14px;height:14px" />
              <span style="margin-left:5px;font-size:14px;font-weight:500">联系方式</span>
            </span>
            <span style="margin-left:20px">
              <img src="@/assets/images/contact/data_icon03.png" alt style="width:14px;height:14px" />
              <span style="margin-left:5px;font-size:14px;font-weight:500">公司名称</span>
            </span>
            <span style="margin-left:20px">
              <img src="@/assets/images/contact/data_icon04.png" alt style="width:14px;height:14px" />
              <span style="margin-left:5px;font-size:14px;font-weight:500">合作内容</span>
            </span>
          </p>
          <p class="content-detail">发送至邮箱</p>
          <div class="content-detail">
            <table
              border="1"
              cellspacing="0"
              cellpadding="0"
              width="70%"
              height="180px"
              style="text-align:center;border-color:#eeeeee"
            >
              <tr>
                <td>商务合作</td>
                <td>zhangxiix@suzhouhengtai.com</td>
                <td>13411156458</td>
              </tr>
              <tr>
                <td>房源托管</td>
                <td>siwciawt@suzhouhengtai.com</td>
                <td>13151146456</td>
              </tr>
              <tr>
                <td>媒体合作</td>
                <td>xsagge@suzhouhengtai.com</td>
                <td>13151146456</td>
              </tr>
            </table>
          </div>
        </div>
      </div> -->

      <!-- <div class="contact_list"> -->
        <!-- <p class="title">投诉反馈</p> -->
        <!-- <span style="font-size:18px">COMPLAINT</span> -->
        <!-- <p class="line"></p> -->
      <!-- </div> -->

      <!-- <div class="feedback"> -->
        <!-- <img src alt width="70%" height="100%" />
        <dl class="feed">
          <dt>欢迎提出您的建议</dt>
          <dt>或使用过程中遇到的问题</dt>
          <dt>投诉反馈></dt>
        </dl>-->
        <!-- <Row> -->
          <!-- <i-col span="16"> -->
            <!-- <img -->
              <!-- src="@/assets/images/contact/complaint_pic.png" -->
              <!-- alt -->
              <!-- style="width:100%;height:298px" -->
            <!-- /> -->
          <!-- </i-col> -->
          <!-- <i-col span="8" class="right-feedback"> -->
            <!-- <p style="font-size:18px;font-weight:bold;color:#454546">欢迎提出您的建议</p> -->
            <!-- <p style="font-size:18px;font-weight:bold;color:#454546">或使用过程中遇到的问题</p> -->
            <!-- <p class="feedback-btn" @click="complaint">投诉反馈></p> -->
          <!-- </i-col> -->
        <!-- </Row> -->
      <!-- </div> -->
    </div>

    <footer-nav></footer-nav>

    <!-- 投诉建议 -->
    <complaint-proposal :showObj.sync="isShowDraft" :height="600" :width="730"></complaint-proposal>

    <!-- 定位栏 -->
    <location-column></location-column>
  </div>
</template>

<script>
import headerNav from "@/components/header-nav";
import footerNav from "@/components/footer-nav";
import complaintProposal from "@/components/complaint-proposal.vue";
import { _getContactUsList } from "@/api/contactUs";
export default {
  name: "about-us",
  components: { headerNav, footerNav, complaintProposal },
  data() {
    return {
      isShowDraft: false,
      contactUsInfo:{},
    };
  },
  methods: {
    // 投诉建议
    complaint() {
      if (localStorage.getItem("userInfo")) {
        this.isShowDraft = true;
      } else {
        this.$router.push({ name: "login" });
        sessionStorage.setItem("checkedIndex", 7);
      }
    },
    // 获取信息
    getContactUsList(id) {
      _getContactUsList().then((res) => {
        if (res.code == 0) {
          this.contactUsInfo = res.data;
        } else {
          this.$Message.error(res.message);
        }
      }).catch((err) => {
        this.$Message.error("获取解决方案详情失败,请稍后再试");
      });
    },
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
    // this.$store.commit("changeAppointment", true);
    // this.$store.commit("changeLocation", 230);

    // 获取联系我们
    this.getContactUsList();
  }
};
</script>

<style scoped>
.contactUs-container {
  /* min-width: 1366px; */
}
.main {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 50px;
}
.main-header {
  width: 100%;
  height: 386px;
  text-align: center;
  background-image: url("../assets/images/contact/contactus_banners.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.header-content {
  height: 160px;
  margin: auto;
  padding-top: 120px;
}
.title {
  font-size: 40px;
  margin-top: 30px;
  font-weight: bold;
}
.titles{
  font-size: 18px;
}
.contact_list {
  margin-top: 40px;
  max-width: 1300px;
  margin: auto;
}
.line {
  width: 80px;
  margin: auto;
  border-bottom: 2px solid #d34100;
  margin-top: 10px;
  margin-bottom: 30px;
}
.box{
  margin: 0 auto;
  /* width: 900px; */
  display: flex;
}
.leftImgs{
  width: 460px;
  height: 361px;
}
.content{
  flex: 1;
  padding: 20px 0px 20px 100px;
}
.hotLine {
  height: 70px;
  /* text-align: center; */
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
}
.hontLineIMg {
  padding-left: 45px;
  position: relative;
  top: -8px;
}
.hotLine-content {
  padding-left: 20px;
  text-align: left;
}
.joinContent {
  height: 120px;
  text-align: center;
  padding-top: 30px;
  /* border: 1px solid gray; */
  background-color: #ffffff;
  margin-top: 20px;
}
.duty {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.experience {
  padding-top: 10px;
  color: #666666;
  font-size: 14px;
}
.email {
  margin: auto;
  margin-top: 30px;
  font-size: 18px;
}
.bgc_cooperation {
  width: 100%;
  margin-top: 30px;
  height: 360px;
  text-align: center;
  position: relative;
  background-image: url("../assets/images/contact/cooperation_bg.png");
}
.cooperation_content {
  width: 1200px;
  margin: auto;
  height: 600px;
  background: white;
  background-color: #ffffff;
  margin-top: -160px;
  position: relative;
}
.title-content {
  text-align: left;
  font-size: 18px;
  padding-left: 20px;
  padding-top: 20px;
  color: #666666;
}
.content-detail {
  text-align: left;
  padding-top: 10px;
  padding-left: 20px;
  color: #666666;
  font-size: 14px;
}
.cooperation_detail {
  color: #d95e2d;
  background-color: #faefe8;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  font-size: 16px;
  margin-left: 10px;
  padding: 5px;
}
.feedback {
  width: 1200px;
  height: 300px;
  margin: auto;
  margin-top: 40px;
}
.right-feedback {
  background-color: #f0f1f2;
  height: 298px;
  text-align: center;
  padding-top: 80px;
}
.feedback-btn {
  margin: 30px auto;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 18px;
  width: 160px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  background-color: #d75f2d;
  cursor: pointer;
}
.contact_title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.contact_content {
  font-size: 14px;
  color: #666666;
}

@media screen and (max-width: 768px) {
  .main {
    padding-top: 50px;
  }
  .main-header {
    height: 166px;
  }
  .title {
    font-size: 20px;
  }
  .titles{
    font-size: 12px;
  }
  .about_list {
    margin-top: 20px;
    padding: 10px;
  }
  .about_list p {
    font-size: 14px;
  }
  .ideaContent {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ideaFirst {
    width: 80%;
    margin: auto;
    font-size: 14px;
    height: 100px;
  }
  .ideaFirst2 {
    margin-top: 10px;
  }
  .certificateContainer {
    width: 80%;
    margin: auto;
  }
  .aboutImg {
    width: 200px;
    height: 160px;
  }

  .box{
    /* margin: 0 auto; */
    /* width: 900px; */
    /* display: flex; */
    flex-direction: column;
  }
  .leftImgs{
    width: 100%;
    height: 160px;
  }
  .content{
    padding: 10px;
  }
}
</style>